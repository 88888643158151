import {
        // ceil,
        floor
    } from 'lodash';
import Swal from 'sweetalert2'

export default {
    logo: (url) => {
        return <img loading="lazy" src={url} alt="B Solution Services" height="54px" className="pt-3"/>
    },
    brandName: () => {
        return 'B Solution Services'
    },
    deleteMessage: () => {
        return "You won't be able to revert this!"
    },
    changeLeaderMessage: () => {
        return 'Are you sure want to change leader'
    },
    changeStatusMessage: () => {
        return 'Are you sure want to change status'
    },
    placeholderSearch: () => {
        return 'Search ...'
    },
    placeholderSearchPhone: () => {
        return 'Search Phone or Name'
    },
    exportExcelMessage: () => {
        return 'Are you sure want to export Excel'
    },
    resetMessage: () => {
        return 'Are you sure want to reset Connect'
    },
    placeholderDate: () => {
        return 'MM/YYYY'
    },
    /**
     * no auth
     * @returns RSX
     */
    reLogin: () => {
        return (
            <div className="text-danger text-center mt-4">
                You do not have permission to access for this page!
                <br/>
                <a href={process.env.REACT_APP_PUBLIC_URL + "/"} className="btn btn-warning">Login</a> Try again!
            </div>
        )
    },
    /**
     * RSX show messages after action
     *
     * @param {string} message
     * @param {string} className
     * @returns
     */
    renderErrorMessage: (message, className = "text-danger") => {
        return (
            <div className={className}>
                {message}
            </div>
        )
    },

    // warning use lib
    warning: (message, status) => {
        return Swal.fire({
            icon: status ? "success" : "error",
            text:message,
            showConfirmButton: true,
            timer: 5000
        })
        // return alert(message)
    },

    confirm: (message, callbackFunction) => {
        Swal.fire({
            title: 'Are you sure?',
            text: message, //"You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if(result.isConfirmed) {
                // call-back
                callbackFunction()
            }
        });

        // return window.confirm(message)
    },

    // format time number 2 charactor
    getMonthYear: (month, key) => {
        return month.split('-')[key] ? Number(month.split('-')[key]) : 0
    },
    pad: (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString()
    },
    totalWorkTime: function (hours, minutes)  {
        return minutes > 60
            ? hours + floor(minutes/60) + ':' +  floor((minutes/60 - floor(minutes/60)) * 60)
            : this.pad(hours) + ':' + this.pad(minutes)
    },

    // Database difine
    // user role
    isSuperAdmin: (type) => {
        return type === 'super-admin'
    },

    userRole: (type) => {
        var val = 0
        switch (type) {
            case 'user':
                val = 0
                break;
            case 'super-admin':
                val = 1
                break;
            case 'manager':
                val = 2
                break;
        }

        return val

        return type === 1 || type === '1' || type === 'super-admin' ? 'super-admin' : 'user'
    },
    userRoleSuperAdmin: () => {
        return 'super-admin'
    },
    userRoleUser: () => {
        return 'user'
    },
    userRoleManager: () => {
        return 'manager'
    },

    //user leader
    isLeader: (isLeader) => {
        return isLeader === 1 || isLeader === '1'
    },
    userLeader: () => {
        return 'Yes'
    },
    userMember: () => {
        return 'No'
    },


    employeeGroup: (groupId) => {
        var val = ''
        switch (groupId) {
            case 1:
                val = 'GBG'
                break;
            case 2:
                val = 'DEV & IT'
                break;
            case 3:
                val = 'Football Ticket Net'
                break;
            case 4:
                val = 'Gigsberg'
                break;
            default:
                val = 'GBG'
                break;
        }

        return val
    },

    shortString: (str, num) => {
        if(str.length > num) {
            str = str.substring(0,num);
        }

        return str;
    },
    replacenewLine:(string) => {
        return string.replace(/(\r\n|\r|\n)/g, '<br>');
    },

    urlMedia:() => {
        return process.env.REACT_APP_MEDIA_ENDPOINT
    },

    viewImage:(imageUrl, imageWidth, imageHeight, title, content) => {
        Swal.fire({
            title: title,
            text: content,
            imageUrl: imageUrl,
            imageWidth: imageWidth,
            imageHeight: imageHeight,
            imageAlt: content,
            heightAuto: true,
            padding: "1rem"
        })
    },

    getSrcNone: () => {
        return "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D"
    },

    getSrcDemo: (height, width) => {
        return "https://picsum.photos/" + height + "/" + width
    },

    isImgUrl: (url) => {
        const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
            let delayDebounceFn = setTimeout(() => {
                img.onerror = () => resolve(false);
                img.onload = () => resolve(true);
            }, 200);

            clearTimeout(delayDebounceFn)
        });
    }
}
