import React from 'react'
import loading from '../../assets/images/loading.gif'

const Loading = ({className = ""}) => {
    //destructering
    return <div className="centered text-center w-50"
        style={{ 
            zIndex:999
         }}
    >
        <img loading="lazy" src={loading} alt="Loading..." className={className}
             style={{borderRadius: "50%", opacity: "0.3"}}/>
    </div>
}

export default Loading
