import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/layouts/Loading';
// import Header from './components/layouts/Header';
// import Footer from './components/layouts/Footer';

const Login = lazy(() => import ('./components/screens/auth/Login'));
const UserManager = lazy(() => import ('./components/screens/system/UserManager'));
const ChatManager = lazy(() => import ('./components/whatsapp/ChatManager'));
const SendMessageManager = lazy(() => import ('./components/whatsapp/SendMessageManager'));
const MediaFileManager = lazy(() => import ('./components/whatsapp/MediaFileManager'));
const ContactManager = lazy(() => import ('./components/whatsapp/ContactManager'));
const ConnectWhatsapp = lazy(() => import ('./components/screens/auth/ConnectWhatsapp'));
const WhatsappManager = lazy(() => import ('./components/whatsapp/WhatsappManager'));
const TemplateManager = lazy(() => import ('./components/whatsapp/TemplateManager'));
const MessageManager = lazy(() => import ('./components/whatsapp/MessageManager'));
const LangManager = lazy(() => import ('./components/whatsapp/LangManager'));

function App() {
    return (
        <div className="app">
            <Suspense fallback={<Loading className="w-25"/>}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/user" element={<UserManager />} />
                    <Route path="/connect" element={<ConnectWhatsapp />} />
                    <Route path="/whatsapp" element={<WhatsappManager />} />
                    <Route path="/template" element={<TemplateManager />} />
                    <Route path="/lang" element={<LangManager />} />
                    <Route path="/contact" element={<ContactManager />} />
                    {/* upload file to send message by <object media id> */}

                    <Route path="/media-file" element={<MediaFileManager />} />
                    <Route path="/message-list" element={<MessageManager />} />
                    <Route path="/chat/:wa_id/" element={<ChatManager />} />
                    <Route path="/new-chat" element={<SendMessageManager />} />                
                </Routes>
                {/* <Footer /> */}
            </Suspense>
        </div>
    );
}

export default App;
