import React, {useEffect, useState} from 'react'
import AuthService from '../libs/service/AuthService';
import AuthContext from './AuthContext';
import { useLocation } from 'react-router-dom';
import UserService from '../libs/service/UserService';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import Global from '../libs/Global';
import { useRef } from 'react';

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({})
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isAuthentiacted, setIsAuthentiacted] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [token, setToken] = useState();
    const [tokenExpire, setTokenExpire] = useState();
    const [menu, setMenu] = useState('');

    const [tokenWhatsapp, setTokenWhatsapp] = useState('');
    const firstRender = useRef(true)

    function onClickMenu(selectedMenu) {
        setMenu(selectedMenu)
        localStorage.setItem('selectedMenu', selectedMenu)
        // console.log(selectedMenu)
    }

    const toggleAuth = () => {
        setIsAuthentiacted(!isAuthentiacted)
        localStorage.clear()
    }

    const location = useLocation()

    //timezone
    const [tz, setTz] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [datetime, setDatetime] = useState(moment());

    useMemo(() => {
        const tzValue = tz.value ?? tz; //??
        setDatetime(datetime.tz(tzValue));
    }, [tz, datetime]);


    function handleSubmit(formData) {
        setIsSubmitted(true)
        AuthService.login(formData)
            .then(reponse => {
                setIsSubmitted(false)
                setAuth(reponse)
                setIsAuthentiacted(true)
                setErrorMessage('')
            }
            ).catch(error => {
            setIsSubmitted(false)
            setErrorMessage(error.response.data.message ? error.response.data.message : error.response.statusText)
        })
    }

    const handleConnectHRM = (formData) => {
        setIsSubmitted(true)
        UserService.connectHRM(formData)
            .then(response => {
                setIsSubmitted(false)
                setToken(response.data.token)
                setErrorMessage('')
                // if (response.data.status === "success") {
                //     localStorage.setItem("tokenHRM", response.data.token)
                // }
            }
            ).catch(error => {
                setIsSubmitted(false)
                setErrorMessage(error.response.data.message ? error.response.data.message : error.response.statusText)
            })
    }

    const handleTokenHRM = () => {
        setIsSubmitted(true)
        UserService.tokenHRM([])
        .then(response => {
            setIsSubmitted(false)
            setToken(response.data.token)
            if (response.data.status === "success") {

            }
            localStorage.setItem("tokenHRM", response.data.token)
        }).catch(error => {
            setIsSubmitted(false)
        })
    }

    const handelResetConnectHRM = () => {
        // console.log('reset')
        UserService.resetTokenHRM([])
        .then(response => {
            setToken(null)
            localStorage.setItem("tokenHRM", null)
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }

    const makeOAuthWhatsapp = (formData) => {
        UserService.makeOAuthWhatsapp(formData)
        .then(response => {
            // console.log(response)
            let accessToken = response.data.data.access_token
            localStorage.setItem("tokenWhatsapp", accessToken)
            setTokenWhatsapp(accessToken)
            Global.warning(response.data.message, true)
        }).catch(error => {
            console.log(error)
        })
    }

    const resetTokenWhatsapp = () => {
        // console.log('reset')
        UserService.resetTokenWhatsapp([])
        .then(response => {
            console.log(response)
            Global.warning(response.data.message, true)
            // clear token localstore / set toke db null
            setTokenWhatsapp('')
            localStorage.setItem('tokenWhatsapp', '')
        }).catch(error => {
            console.log(error)
        })
    }

    const getTokenWhatsapp = () => {
        UserService.getTokenWhatsapp([])
        .then(response => {
            let accessToken = response.data.data.access_token
            localStorage.setItem("tokenWhatsapp", accessToken)
            setTokenWhatsapp(accessToken)
            // console.log(accessToken)
        }).catch(error => {
            // console.log(error)
        })
    }

    // cheched OK
    useEffect(() => {
        //load Payroll Types to selected box
        if (firstRender.current) {
            firstRender.current = false;
            getTokenWhatsapp()

            setAuth(
                localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : []
            )
        }

        setTokenWhatsapp(
            localStorage.getItem('tokenWhatsapp') ? localStorage.getItem('tokenWhatsapp') : ''
        )
    }, [
        tokenWhatsapp //reset | new auth
    ])

    const authContext = {
        auth,
        isSubmitted,
        isAuthentiacted,
        toggleAuth,
        handleSubmit,
        errorMessage,
        setErrorMessage,
        // hasRenderUser,
        handleConnectHRM,
        token,
        setToken,
        handelResetConnectHRM,
        tz,
        setTz,
        datetime,
        setDatetime,
        menu, setMenu,
        onClickMenu,
        makeOAuthWhatsapp,
        tokenWhatsapp,
        setTokenWhatsapp,
        resetTokenWhatsapp
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
