import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AuthProvider from './context/AuthProvider';
import './index.css';

// Auto check on 10s
function emitGetMessage(page, timer) {
    setInterval(() => {
        window.dispatchEvent(
            new CustomEvent(`pushNotifyMessage-${page}`, {
            // new CustomEvent(`pushNotification-${page}`, {
                detail: `Listen new message to nfc ${page}`
            })
        )
    }, timer) 
}

emitGetMessage(1, 20000) //20s

// timer
// emitGetMessage(2, 10000) //10s
// emitGetMessage(1, 300000) //180s = 5m

// const baseUrl = document.getElementsByTagName('base')[0].getAttribute['href']
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
            <AuthProvider>
                <App/>
            </AuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
