import axios from 'axios'

const AuthService = {
    login: (param) => {
        return axios.post(
            process.env.REACT_APP_API_ENDPOINT + '/login',
            param
        ).then(response => {
            if (response.data.status === "success") {
                localStorage.setItem("auth", JSON.stringify(response.data))
            }

            return response.data
        })
    },
    getCurrentAuth: () => {
        return localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : []
    },

    //connectHRM: (param) => {
    //     return axios.post(
    //         process.env.REACT_APP_API_ENDPOINT + '/connect',
    //         param
    //     ).then(response => {
    //         if (response.data.status === "success") {
    //             localStorage.setItem("tokenHRM", JSON.stringify(response.data.token))
    //         }
    //         console.log(response)
    //         return response.data
    //     })
    // },

    // getTokenHRM: (param) => {
    //     return axios.post(
    //         process.env.REACT_APP_API_ENDPOINT + '/token-hrm',
    //         param
    //     ).then(response => {
    //         if (response.data.status === "success") {
    //             // localStorage.setItem("auth", JSON.stringify(response.data))
    //         }
    //         console.log(response)

    //         return response.data
    //     })
    // },
}

export default AuthService
